<script lang="ts">
  import { Icon } from "@steeze-ui/svelte-icon";
  import Spinner from "$lib/components/ui/components/Spinner.svelte";
  export let name;
  export let icon: any = null;
  export let iconTheme = "outline";
  export let loading = false;
  export let placeholder = null;
  export let maxLength = 524288;
  export let label = null;
  export let required = false;
  export let readonly = false;
  export let disabled = false;
  export let value = null;
  export let type = "text";
  export let bind = null;
  export let onBlur = null;
  export let onInput = null;
  export let onKeyup = null;
  export let error = null;
  export let errorMsg = null;

  function typeAction(node) {
    node.type = type;
  }
</script>

<div>
  {#if label}
    <label for={name} class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
      >{label}{#if required}<span class="text-red-500 ml-1">*</span>{/if}</label
    >
  {/if}
  <div class="relative">
    {#if icon}
      {#if loading}
        <div class="absolute inset-y-0 left-0 flex items-center pointer-events-none">
          <Spinner size={5} />
        </div>
      {:else}
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <Icon src={icon} theme={iconTheme} class="w-5 h-5 stroke-2 text-gray-500 dark:text-gray-400" />
        </div>
      {/if}
    {/if}
    <input
      id={name}
      {name}
      maxlength={maxLength}
      use:typeAction
      autocomplete="off"
      {readonly}
      {disabled}
      on:input={onInput}
      on:keyup={onKeyup}
      on:blur={onBlur}
      bind:this={bind}
      bind:value
      class="block w-full p-2.5 {icon
        ? 'pl-10'
        : ''} h-[2.5rem] text-sm text-gray-900 rounded-md outline-none border bg-gray-50 dark:bg-gray-700 dark:placeholder-gray-400 dark:text-white focus:ring-emerald-500 focus:border-emerald-500 dark:focus:ring-emerald-500 dark:focus:border-emerald-500 {$$props.class} {error &&
      !value
        ? 'border-red-500 dark:border-red-500'
        : 'border-gray-300 dark:border-gray-500'}"
      {placeholder}
    />
  </div>
  {#if error}
    <p class="mt-2 text-sm text-red-600 dark:text-red-500">
      <span class="font-medium">{errorMsg || error}</span>
    </p>
  {/if}
</div>
